.user-view-btn {
  display: flex;
  justify-content: center;
  padding: 4px;
}

.content .main-container-head .table .country {
  text-transform: capitalize
}

.content .main-container-head .table .public-address:hover {
  cursor: pointer;
  color: #d82e35;
}


/* ========== Custom Styles Whitelist Modal =========== */

.main-modal.investor-modal {
  top: 0 !important;
  max-width: 900px !important;
}

.modal.show .modal-dialog.investor-modal {
  transform: translate(0, 10%) !important; 
}

.main-modal.investor-modal .modal-content .modal-body {
  padding: 10px 150px;
}

.investor-modal .input-field {
  margin-bottom: 20px;
}

.investor-modal .input-field .MuiFormLabel-root.Mui-focused,
.investor-modal .input-field .MuiFormLabel-root,
.investor-modal .input-field .MuiInputBase-root,
.investor-modal .input-field .MuiFormHelperText-root,
.investor-modal .input-field .MuiSvgIcon-root {
  color: #ffffff !important;
}

.investor-modal .input-field .MuiInput-underline:after,
.investor-modal .input-field .MuiInput-underline:before,
.investor-modal .input-field .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #ffffff;
}

.investor-modal .input-field .MuiSelect-select:not([multiple]) option {
  background-color: #d82e35;
  padding: 20px !important;
}

.investor-modal .input-field .MuiSelect-select:focus {
  background-color: #d82e35 !important;
}


.investor-modal .input-field .date-picker .MuiInputBase-input {
  color: #ffffff;
}

.investor-modal .input-field .date-field {
  border-radius: 25px;
  background-color: #fff;
  margin-top: 0px;
  margin-bottom: 0px;
}

.investor-modal .input-field .date-field .MuiInputBase-root {
  color: #131321;
  font-size: 13px;
}

.investor-modal .input-field .date-field input {
  height: 15px;
  padding: 15px;
}

.investor-modal .input-field .date-field .MuiInput-underline:after {
  border: none !important;
}

.investor-modal .input-field .date-field .MuiInput-underline:before {
  border: none !important;
}