.user-view-btn {
  display: flex;
  justify-content: center;
  padding: 4px;
}

.content .main-container-head .table .country {
  text-transform: capitalize
}

.content .main-container-head .table .public-address:hover {
  color: #d82e35;
}